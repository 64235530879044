"use client";

import * as React from "react";

import type {
  ResetPasswordOutput,
  SignInInput,
  SignInOutput,
} from "aws-amplify/auth";

export type AuthState = (SignInOutput | ResetPasswordOutput) & {
  username?: SignInInput["username"];
  password?: SignInInput["password"];
};

const AuthContext = React.createContext<{
  authState: AuthState | null;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState | null>>;
}>(undefined);

export const useAuthState = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export const AuthProvider = ({
  children,
  initialAuthState,
}: {
  children: React.ReactNode;
  initialAuthState: AuthState | null;
}) => {
  const [authState, setAuthState] = React.useState<AuthState | null>(
    initialAuthState
  );

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};
