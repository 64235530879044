"use client";

import configureAmplify from "configureAmplify";

type ConfigureAmplifyProps = {
  isTestUser: boolean;
};

export default function ConfigureAmplifyClientSide({
  isTestUser,
}: ConfigureAmplifyProps) {
  configureAmplify(isTestUser);
  return null;
}
